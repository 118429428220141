<template>
	<div class="page">
		<div class="xc_top_box">
			<div class="w1240 top_con">
				<div class="logobox">
					<img class="img" src="../assets/static/logoimg.png"/>
				</div>
				<div class="step_box">
					<div class="stepitem relative">
						<div class="text1_hover">1</div>
						<div class="text2_hover">我的采购车</div>
						<img class="img" src="../assets/static/Rectangle317.png" />
					</div>
					<div class="stepitem relative">
						<div class="text1">2</div>
						<div class="text2">确认订单信息</div>
						<img class="img" src="../assets/static/Rectangle318.png" />
					</div>
					<div class="stepitem relative">
						<div class="text1">3</div>
						<div class="text2">提交订单成功</div>
						<img class="img" src="../assets/static/Rectangle318.png" />
					</div>
				</div>
			</div>
		</div>
		<!-- 商品 -->
		<div class="block20"></div>
		<div class="goods_car_box w1240">
			<div class="type_box">
				<div class="select_box">
					<div @click="CartCheckAllApi" class="iconfont cart-pro-select icon-yixuanze" :class="{'active':selectAllStatus}"></div>
					<span class="total-select">全选</span>
				</div>
				<div class="text2"><span>商品信息</span></div>
				<div class="text3"><span>单价</span></div>
				<div class="text4"><span>数量</span></div>
				<div class="text5"><span>小计</span></div>
				<div class="text6"><span>操作</span></div>
			</div>
			<div class="block20"></div>
			<div class="type_box">
				<div class="text1">{{bsnName}}</div>
				<div class="text_1">
					<div class="text_1_1">配送说明</div>
					<div class="text_1_2">满0元包邮</div>
				</div>
				<div class="text6 pointer">
					<!-- <span>展开</span><span class="mixicon iconfont icon-xiangxia"></span> -->
				</div>
			</div>
			<div class="goodslist_box">
				<div class="itemobj" v-for="(item,index) in carts" :key="index">
					<div @click="CartCheckApi(index)" class="iconfont cart-pro-select icon-yixuanze" :class="{'active':item.is_checked}"></div>
					<div class="img"><img :src="item.productImageUrl" /></div>
					<div class="name_box">
						<div class="text1">{{item.medicineDrugsSku.goodsName}}</div>
						<div class="text2">生产厂家：{{item.medicineDrugsSku.manufacturerName}}</div>
						<div class="text3">生产日期： {{item.medicineDrugsSku.medicineDrugs.productionDate}}</div>
						<div class="text4">有效期： {{item.medicineDrugsSku.medicineDrugs.validityDate}}</div>
					</div>
					<div class="con_box">
						<div class="text1">规格：{{item.medicineDrugsSku.medicineDrugs.baseSku}}</div>
						<div class="text2">单位：{{item.skuName}}</div>
						<div class="text3">批号/批次：{{item.batchid || item.medicineDrugsSku.medicineDrugs.batchNum}}</div>
					</div>
					<div class="con_box1">
						<div class="text1">￥{{item.finalPrice}}</div>
					</div>
					<div class="con_box2">
						<div class="caraddjian">
							<div class="jian" @click="changeCartNumber('jian',index)">
								<span class="mixicon iconfont icon-jian"></span>
							</div>
							<input class="input" type="number" v-model="item.goodsNum" placeholder="请输入"/>
							<div class="add" @click="changeCartNumber('jia',index)">
								<span class="mixicon iconfont icon-jia"></span>
							</div>
						</div>
						<!-- <div class="stockNum">库存</div> -->
					</div>
					<div class="con_box3">
						<div class="text1">￥{{item.danprice}}</div>
					</div>
					<div class="edit_box">
						<div class="mixicon iconfont icon-shanchu1" @click="CartDelApi(index,item.id)"></div>
					</div>
				</div>
			</div>
			<div class="block20"></div>
		     <!-- 操作盒子 -->
			 <div class="goods_edit_box">
				<div class="select_box">
					<div @click="CartCheckAllApi" class="iconfont cart-pro-select icon-yixuanze" :class="{'active':selectAllStatus}"></div>
					<span class="total-select">全选</span>
				</div>
				<!-- <div class="deltips">删除选中的商品</div> -->
				<div class="goodsnum">共<span>{{ alllenght }}</span>商品，已选<span>{{ lenght }}</span></div>
				<div class="line"></div>
				<div class="goodsprice">商品合计：<span>¥ {{ totalPrice }}</span></div>
				<div class="orderbtn" @click="clickorderPay">核对订单</div>
			 </div>
		</div>
		
		<div class="block40"></div>
	</div>
</template>

<script>
//引入状态管理
import {mapState} from 'vuex'
import { showLoading, hideLoading } from '@/common/loading.js';
export default {
	data() {
		return {
			uid:"",
			selectAllStatus:false,
			carts: [], // 购物车列表
			currentPage:1,
			complete:false,
			totalPrice: 0, // 总价，初始为0
			lenght: 0, //选择商品数量
			alllenght: 0, //商品数量
			lenghttext: '立即采购',
		};
	},
	//状态值
	computed: {
		...mapState(['homeUrl','bsnName','uuid','nickName','avatarUrl','phone','isLogin','shareCode']),
	},
	mounted: function() {
		var _this = this
		if(this.isLogin){
			this.currentPage = 1
			this.carts = []
			this.complete = false
			this.getPageCart()
		}else{
			this.$alert('登录时限失效，请重新登录~', '温馨提示', {
			    confirmButtonText: '确定',
			    callback: action => {
			        _this.$router.push({
			        	name: "home"
			        });
			    }
			});
		}
	},
	methods: {
		//获取购物车
		getPageCart() {
			var _this = this
			if(this.complete){
				console.log("loading all")
				return;
			}
			var params = {}
			params["userUuid"] = this.uuid
			params["userPhone"] = this.phone
			params["currentPage"] = this.currentPage
			params["pageSize"] = 888
			this.$http.post('medicine/medicineDrugsCart/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					if(records.length>0){
						_this.hasList = true
						records.forEach((item,index)=>{
							item["is_checked"] = false
							item["productImageUrl"] = item.medicineDrugsSku.medicineDrugs.mainImages[0]
							item["finalPrice"] = item.medicineDrugsSku.finalPrice || 0
							item["danprice"] = (Number(item.goodsNum) * Number(item.finalPrice)).toFixed(2)
							if(item.choiceUnit){
								var unit = item.choiceUnit.split(":")
								if(unit){
									item["skuName"] = unit[0]
									try{
										item["skuNum"] = Number(unit[1])
									}catch(e){
										item["skuNum"] = 1
										//TODO handle the exception
									}
								}
							}
						})
						_this.currentPage +=1
						_this.carts = _this.carts.concat(records)
						_this.isCheckedNumber()
					}else{
						_this.complete = true
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//商品数量
		changeCartNumber(type,index){
			var _this = this
			var carts = this.carts
			var goodsNum = Number(carts[index].goodsNum)
			var danwei = Number(carts[index].skuNum)
			if(type=="jian"){
				//减少,等于1不减
				if(goodsNum<=danwei){
					this.$cache.showfailToast("已减到最小了")
					return;
				}
				goodsNum -=danwei;
			}else{
				goodsNum +=danwei;
			}
			var params = {}
			params["userUuid"] = this.uuid
			params["userPhone"] = this.phone
			params["choiceUnit"] = carts[index].choiceUnit
			params["goodsUuid"] = carts[index].goodsUuid
			params["goodsNum"] = goodsNum
			params["type"] = 1 //0增加购物车 1设置商品数
			params["batchid"] = carts[index].batchid
			showLoading();
			this.$http.post('medicine/medicineDrugsCart/putCart', params).then(function(res) {
				//数据处理
				hideLoading();
				if (res.code == 200) {
					_this.$message.success("成功~")
					//获取计算金额
					carts[index].goodsNum = goodsNum
					carts[index].danprice = (goodsNum* Number(carts[index].finalPrice)).toFixed(2)
					_this.carts = carts
					_this.isCheckedNumber()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//查看当前选择的数量
		isCheckedNumber() {
		    var number = 0;
			var alllenght = 0
			var totalPrice = 0 //价格
		    for (var i = 0; i < this.carts.length; i++) {
				//alllenght += Number(this.carts[i].goodsNum)
				alllenght++;
		        if (this.carts[i].is_checked) {
		           number++;
				   totalPrice += this.carts[i].finalPrice * this.carts[i].goodsNum
		        }
		    }
			this.totalPrice = totalPrice.toFixed(2)
		    if (number == this.carts.length && number != 0) {
		        this.selectAllStatus = true;
		    } else {
		        this.selectAllStatus = false;
		    }
		    this.lenght = number
			this.alllenght = alllenght
		},
		//单勾选
		CartCheckApi(index){
			var _this = this
			var cartobj = this.carts[index]
			var is_checked = cartobj.is_checked
			cartobj.is_checked = !is_checked
			this.carts[index] = cartobj
			this.isCheckedNumber()
		},
		//全选勾选
		CartCheckAllApi(){
			var _this = this
			console.log("触发")
			if(this.carts.length<=0){
				this.$alert('购物车空空如也，先去首页选购吧~', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				        _this.$router.push({
				        	name: "home"
				        });
				    }
				});
				return;
			}
			var carts = this.carts
			//设置为选中或者取消
			for(var i=0;i<carts.length;i++){
				if(this.selectAllStatus){
					carts[i].is_checked = false
				}else{
					this.selectAllStatus = false
					carts[i].is_checked = true
				}
			}
			_this.carts = carts
			//查看选择数量
			_this.isCheckedNumber()
		},
		//取结算页面
		clickorderPay(){
			var _this = this
			if(this.carts.length<=0){
				this.$alert('购物车空空如也，先去首页选购吧~', '温馨提示', {
				    confirmButtonText: '确定',
				    callback: action => {
				        _this.$router.push({
				        	name: "home"
				        });
				    }
				});
				return;
			}
			if(this.lenght<=0){
				this.$message.error('请先选择需要采购的商品！');
				return;
			}
			var carts = []
			for (var i = 0; i < this.carts.length; i++) {
			    if (this.carts[i].is_checked) {
				   var skuobj = {}
			       skuobj["skuImg"] = this.carts[i].productImageUrl
				   skuobj["skuName"] = this.carts[i].skuName || "",
				   skuobj["cartId"] = this.carts[i].id
				   skuobj["goodsUuid"] = this.carts[i].goodsUuid 
				   skuobj["goodsobj"] = this.carts[i].medicineDrugsSku 
				   skuobj["goodsNum"] = this.carts[i].goodsNum
				   skuobj["goodsPirce"] = this.carts[i].finalPrice
				   skuobj["batchid"] = this.carts[i].batchid
				   carts.push(skuobj)
			    }
			}
			//立即购买
			var orderList = [{
				logo: "/static/logo.png",
				storeName:this.bsnName,
				goodsList:carts,
				physical:1,//虚实物 0虚物 1实物
				remarks:"",//订单备注
			}]
			localStorage.setItem("orderList",JSON.stringify(orderList))
			this.$router.push({ path: '/payPage', query: {ori:"goods",groupType:0 }})
		},
		//删除购物车商品
		CartDelApi(cartIndex,cartId){
			var _this = this
			 this.$confirm('确定要从进货车中删除该商品?', '温馨提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        var params = {
			        	userUuid:_this.uuid,
			        	userPhone:_this.phone,
			        	resetAll:false,
			        	cartIds:[cartId],//购物车商品id
			        };
					this.$http.post('medicine/medicineDrugsCart/resetCart', params).then(function(res) {
						//数据处理
						if (res.code == 200) {
							_this.$message.success("成功~")
							// 清掉集合对象
							var odlist1 = _this.carts
							odlist1.splice(cartIndex, 1)
							_this.carts = odlist1
							//获取计算金额
							_this.isCheckedNumber()
						} else {
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
			    }).catch(() => {
			        this.$message({
			        type: 'info',
			        message: '已取消删除'
			    });          
			});
		},
		
	}
};
</script>
<style lang="scss" scoped>
.cart-pro-select {
	font-size: 20px;
	color: rgba($color: #000000, $alpha: 0.1);
	cursor: pointer;
	&.active{
		color: #1370FB;
	}
}
.total-select{
	margin-left: 5px;
}
</style>
